import axios from "axios";
import { backendUrl } from "./index";
import * as https from 'https';

const agent = new https.Agent({
  rejectUnauthorized: false,
}); 

const instance = axios.create({
  // baseURL: backendUrl,
  baseURL: "https://login360.info:9090/",
  httpsAgent: new https.Agent({  
    rejectUnauthorized: false,
  })
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },

  //Error
  (err) => {
    return err.message;
  }
);

export default instance;
