import React from "react";

const project = () => {
  return (
    <svg
      width="23px"
      height="16px"
      viewBox="0 0 23 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>project</title>
      <g
        id="Kanban-Dashboard-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Tasks"
          transform="translate(-21.000000, -225.000000)"
          stroke="#6C6C6C"
        >
          <g id="menu2" transform="translate(0.000000, 113.000000)">
            <g id="MenuProject" transform="translate(22.000000, 112.000000)">
              <g id="Group-15" transform="translate(0.000000, 1.000000)">
                <line
                  x1="6.023325"
                  y1="7.1372549"
                  x2="2.62395"
                  y2="11.4981176"
                  id="Stroke-9"
                ></line>
                <line
                  x1="12.7537375"
                  y1="8.52206078"
                  x2="8.2203625"
                  y2="6.73417843"
                  id="Stroke-11"
                ></line>
                <line
                  x1="14.794675"
                  y1="7.86168627"
                  x2="18.455675"
                  y2="2.84330392"
                  id="Stroke-13"
                ></line>
                <path
                  d="M3.0625,12.4901961 C3.0625,13.2297941 2.475375,13.8284314 1.75,13.8284314 C1.024625,13.8284314 0.4375,13.2297941 0.4375,12.4901961 C0.4375,11.750598 1.024625,11.1519608 1.75,11.1519608 C2.475375,11.1519608 3.0625,11.750598 3.0625,12.4901961 Z"
                  id="Stroke-1"
                ></path>
                <path
                  d="M8.3125,6.24509804 C8.3125,6.98469608 7.725375,7.58333333 7,7.58333333 C6.274625,7.58333333 5.6875,6.98469608 5.6875,6.24509804 C5.6875,5.5055 6.274625,4.90686275 7,4.90686275 C7.725375,4.90686275 8.3125,5.5055 8.3125,6.24509804 Z"
                  id="Stroke-3"
                ></path>
                <path
                  d="M15.3125,8.92156863 C15.3125,9.66116667 14.725375,10.2598039 14,10.2598039 C13.274625,10.2598039 12.6875,9.66116667 12.6875,8.92156863 C12.6875,8.18197059 13.274625,7.58333333 14,7.58333333 C14.725375,7.58333333 15.3125,8.18197059 15.3125,8.92156863 Z"
                  id="Stroke-5"
                ></path>
                <path
                  d="M20.5625,1.78431373 C20.5625,2.52391176 19.975375,3.12254902 19.25,3.12254902 C18.524625,3.12254902 17.9375,2.52391176 17.9375,1.78431373 C17.9375,1.04471569 18.524625,0.446078431 19.25,0.446078431 C19.975375,0.446078431 20.5625,1.04471569 20.5625,1.78431373 Z"
                  id="Stroke-7"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default project;
