import React from "react";

const tasks = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>verified</title>
      <g
        id="Kanban-Dashboard-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Home"
          transform="translate(-22.000000, -173.000000)"
          fill="#6C6C6C"
          fillRule="nonzero"
        >
          <g id="menu2" transform="translate(0.000000, 107.000000)">
            <g id="MenuProfile" transform="translate(22.000000, 65.000000)">
              <g id="verified" transform="translate(0.000000, 1.000000)">
                <path
                  d="M17.0710547,2.92890625 C15.1823047,1.04019531 12.6710938,0 10,0 C7.32886719,0 4.81761719,1.04019531 2.92890625,2.92890625 C1.04015625,4.81765625 0,7.32890625 0,10 C0,12.6710938 1.04015625,15.1823047 2.92890625,17.0710547 C4.81765625,18.9598047 7.32886719,20 10,20 C12.6710938,20 15.1823047,18.9598047 17.0710547,17.0710547 C18.9598047,15.1823047 20,12.6710938 20,10 C20,7.32890625 18.9598047,4.81769531 17.0710547,2.92890625 Z M10,18.828125 C5.13214844,18.828125 1.171875,14.8678516 1.171875,10 C1.171875,5.13214844 5.13214844,1.171875 10,1.171875 C14.8678516,1.171875 18.828125,5.13214844 18.828125,10 C18.828125,14.8678516 14.8678516,18.828125 10,18.828125 Z"
                  id="Shape"
                ></path>
                <path
                  d="M14.8266166,6.17764034 C14.5954783,5.94077981 14.2206529,5.94077981 13.9895145,6.17768078 L8.76220412,11.5353154 L6.01052494,8.71503296 C5.77938658,8.47813199 5.4045612,8.47813199 5.17338337,8.71503296 C4.94220554,8.95193393 4.94220554,9.33610423 5.17338337,9.57304565 L8.3436136,12.8223142 C8.45920252,12.9407849 8.61070332,13 8.76216466,13 C8.913626,13 9.06516626,12.9407444 9.18071571,12.8223142 L14.8266166,7.03565302 C15.0577945,6.79875205 15.0577945,6.41458176 14.8266166,6.17764034 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default tasks;
