import React from "react";

const pin = () => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Group 5</title>
      <g
        id="Kanban-Dashboard-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Projects"
          transform="translate(-255.000000, -255.000000)"
          stroke="#7D90AA"
        >
          <g id="List1" transform="translate(224.000000, 133.000000)">
            <g id="e1" transform="translate(16.000000, 51.000000)">
              <g id="Group-5" transform="translate(16.000000, 72.000000)">
                <polygon
                  id="Fill-3"
                  points="3.5504 8.88668421 0.572821053 11.8642632 0.163136842 13.8367895 2.13566316 13.4271053 5.11324211 10.4502632"
                ></polygon>
                <path
                  d="M8.10437895,3.02075789 L7.45743158,2.37381053 C7.17006316,2.08570526 7.17006316,1.62002105 7.45743158,1.33191579 L8.49932632,0.290021053 C8.78743158,0.00191578947 9.25458947,0.00191578947 9.54195789,0.290021053 L13.7102737,4.45833684 C13.9976421,4.74570526 13.9976421,5.21212632 13.7102737,5.50023158 L12.6683789,6.54212632 C12.3802737,6.82949474 11.9138526,6.82949474 11.6257474,6.54212632 L10.9795368,5.89517895 L9.33490526,8.34591579 C9.52501053,9.02823158 9.57732632,9.7496 9.48006316,10.4702316 L9.16248421,12.8251789 C9.10795789,13.2289684 8.73658947,13.5111789 8.33353684,13.4566526 C8.17364211,13.4352842 8.02553684,13.3616 7.91132632,13.2473895 L0.752905263,6.08896842 C0.4648,5.80086316 0.4648,5.33444211 0.752905263,5.04707368 C0.866378947,4.93286316 1.01448421,4.85917895 1.17437895,4.83781053 L3.53006316,4.51949474 C4.24995789,4.42223158 4.97206316,4.47528421 5.65437895,4.66465263 L8.10437895,3.02075789 Z"
                  id="Fill-1"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default pin;
