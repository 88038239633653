import React from "react";
import TopNavBar from "../NavigationBar/TopNavBar";
const Projects = () => {
  return (
    <>
      {/* <TopNavBar /> */}
      <div>Projects</div>
    </>
  );
};

export default Projects;
